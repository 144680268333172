<template>
  <div>
        <!-- ========== Inner Banner Start============= -->
        <div class="inner-banner" :style="bgImage">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="banner-content text-center">
                        <h1 :style="textColor">Charity Partner</h1>
                        <span></span>
                        <!-- <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item" :style="textColor"><router-link to="index.html" :style="textColor">Home</router-link></li>
                                <li class="breadcrumb-item active" :style="textColor" aria-current="page">Charity Partner  </li>
                            </ol>
                        </nav> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ========== Inner Banner end============= -->
    <!-- ========== Job Listing Start============= -->
    <div class="job-listing-area pt-120 mb-120">
        <div class="container">
            <div class="row mb-5">
                <div class="col-lg-12">
                    <div v-html="cmsPageInfo" ></div>
                    <!-- <div class="section-title text-center">
                        <h5 class="title">
                            Partner with us to transform lives through employment, to create self-reliant communities!
                        </h5>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-wrapper">
                    <form>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-inner mb-25">
                                    <label for="name">Your Name*</label>
                                    <div class="input-area">
                                        <img src="assets/images/icon/user-2.svg" alt="">
                                        <input type="text" id="name" v-model="charityForm.name" placeholder="Mr. Jackson Mile">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-inner mb-25">
                                    <label for="email">Email*</label>
                                    <div class="input-area">
                                        <img src="assets/images/icon/email-2.svg" alt="">
                                        <input type="text" id="email" v-model="charityForm.email" placeholder="info@example.com">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-inner mb-25">
                                    <label for="phonenumber">Phone*</label>
                                    <div class="input-area">
                                        <img src="assets/images/icon/phone-2.svg" alt="">
                                        <input type="text" id="phonenumber" v-model="charityForm.phone" placeholder="+880-17 *** *** **">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-inner mb-25">
                                    <label for="jobplace">Company Name (Optional)</label>
                                    <div class="input-area">
                                        <img src="assets/images/icon/company-2.svg" alt="">
                                        <input type="text" id="jobplace" v-model="charityForm.company" placeholder="Company Name">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-inner mb-40">
                                    <label for="description">Address</label>
                                    <textarea v-model="charityForm.address" id="description" placeholder="Your company address"></textarea>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-inner">
                                    <button v-if="!isLoading" @click="charityPartner" class="primry-btn-2 lg-btn w-unset" type="button">Become a Charity Partner</button>
                                    <button v-else class="primry-btn-2 lg-btn w-unset" type="button">
                                        <span class="me-3 fs-6 text-white">Processing...</span>
                                        <i class="fa fa-spinner fa-spin text-white" style="font-size:24px">
                                        </i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- ========== Job Listing e nd============= -->
  </div>  
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';
import PrivacyPolicy from './PrivacyPolicy.vue'; // @ is an alias to /src

@Options({
  components: {
    PrivacyPolicy,
  },
  data(){
    return {
      cmsPageInfo: null,
      charityForm: {
        name: '',
        email: '',
        phone: '',
        comapny: '',
        address: '',
      },
      isLoading: false,
      bgImage: '',
      textColor: '',
    }
  },
  computed: {
    ...mapGetters([
        'cmsPages',
        'globalVariables'
    ])
  },
  mounted() {
    var credentials = {
        'page_slug': 'charity-partner',
    }
    this.$store.dispatch('getCMSPages', credentials);
    this.$store.dispatch('getGlobalVariables');
  },
  watch: {
    cmsPages()
    {
      this.cmsPageInfo = this.cmsPages
      console.log(this.cmsPages);
    },

    globalVariables() {
      this.bgImage = 'background-image: url('+this.globalVariables._banner_image+')';
      this.textColor = 'color: '+this.globalVariables._banner_text_color+' !important;';
    }
  },
  methods: {
    charityPartner() {
        this.isLoading = true;
        try {
            this.isLoading = true;
            var credentials = this.charityForm;
            this.$store.dispatch('addPartner', credentials)
            console.log('this is just test');
            window.setTimeout(() => {
                this.isLoading = false;
            }, 5000);
        } catch (error) {
            console.log(error);
        }
    }
  },
})
export default class PrivacyPolicies extends Vue {}
</script>
